import React from "react";
import { Grid, Heading, Paragraph, Text } from "theme-ui";
import { useVariantByBreakpoint } from "utils";
import Link from "./Link";

const SliderHeader = ({
  title,
  subtitle,
  link,
  linkText = "View all",
  totalLength,
  sx,
  ...props
}) => {
  const variantSelector = useVariantByBreakpoint();

  return (
    <Grid
      columns="1fr max-content"
      gap={1}
      sx={{
        mb: 6,
        ...sx,
      }}
      {...props}
    >
      <Heading
        as="h4"
        variant={variantSelector(["subtitle1", "heading5", "heading4"])}
        sx={{
          gridColumn: "1",
          gridRow: "1",
        }}
      >
        {title}
      </Heading>
      {subtitle && (
        <Paragraph
          variant="body1"
          sx={{
            maxWidth: 150,
            wordWrap: "break-word",
            overflowWrap: "break-word",
            gridColumn: ["1 / span 2", "1"],
            gridRow: "2",
            color: "text.secondary",
          }}
        >
          {subtitle}
        </Paragraph>
      )}
      {link && (
        <Link
          to={link}
          sx={{
            display: totalLength > 2 ? "flex" : "none",
            pl: 6,
            gridColumn: "2",
            gridRow: ["1", "1 / span 2"],
            alignSelf: "end",
          }}
        >
          {linkText}
          {totalLength && (
            <Text
              sx={{ display: ["none", "flex"], whiteSpace: "nowrap", pl: 1 }}
            >
              ({totalLength})
            </Text>
          )}
        </Link>
      )}
    </Grid>
  );
};

export default SliderHeader;
