import React from "react";
import { Flex, Heading, Text } from "theme-ui";

import Slider from "components/Slider";
import Card from "components/card/Card";
import LimitedLines from "components/LimitedLines";
import Link from "components/Link";

import { formatedAddress, useVariantByBreakpoint } from "utils";
import { vendorPageUrl } from "utils/urls";

const VendorSlider = ({ vendorList, ...props }) => {
  const variantSelector = useVariantByBreakpoint();

  return (
    <Slider {...props}>
      {vendorList.map((vendor) => (
        <Link
          to={vendorPageUrl(vendor)}
          key={vendor.slug}
          sx={{
            all: "unset",
          }}
        >
          <Card
            type="white"
            orientation="portrait"
            size={variantSelector(["large", null, "extra_large"])}
            image={vendor.image}
            sx={{
              height: "full",
              cursor: "pointer",
            }}
          >
            <Flex sx={{ flexDirection: "column", height: "full", py: 3 }}>
              <Heading
                as="h4"
                variant={variantSelector(["subtitle1", null, "heading6"])}
                sx={{ mb: [2, null, 4] }}
              >
                {vendor.name}
              </Heading>
              <LimitedLines
                variant={variantSelector(["body2", null, "body1"])}
                lines={3}
                sx={{
                  mb: 4,
                }}
              >
                {vendor.description}
              </LimitedLines>
              {vendor.contactInfo?.address && (
                <Text
                  sx={{ mt: "auto" }}
                  variant={variantSelector(["body3", null, "body2"])}
                  color="text.secondary"
                >
                  {formatedAddress(vendor.contactInfo)}
                </Text>
              )}
            </Flex>
          </Card>
        </Link>
      ))}
    </Slider>
  );
};

export default VendorSlider;
