import React from "react";
import { Grid } from "theme-ui";
import SliderHeader from "components/SliderHeader";
import Tile from "explore/components/Tile";

const FeaturedExperiences = ({
  vendorList,
  title,
  subtitle,
  link,
  linkText,
  strapiCity,
  allVendorTags,
  sx,
  ...props
}) => {
  return (
    <>
      {vendorList.length > 0 && (
        <>
          <SliderHeader
            title={title}
            link={link}
            linkText={linkText}
            totalLength={vendorList.length}
            subtitle={subtitle}
            sx={{
              mb: 10,
            }}
          />

          <Grid
            gap={5}
            columns={["1fr", "1fr 1fr", "1fr 1fr"]}
            sx={{
              justifyContent: "center",
              ...sx,
            }}
            {...props}
          >
            {vendorList.slice(0, 6).map((vendor) => (
              <Tile element={vendor} />
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default FeaturedExperiences;
