import React from "react";
import { Flex, Heading, Text } from "theme-ui";

import Slider from "components/Slider";
import Card from "components/card/Card";
import Link from "components/Link";
import SliderHeader from "components/SliderHeader";
import { propertyPageUrl } from "utils/urls";
import { formatedAddress, useVariantByBreakpoint } from "utils";
import LimitedLines from "components/LimitedLines";

const PropertiesSlider = ({ strapiCity, propertiesList, ...props }) => {
  const variantSelector = useVariantByBreakpoint();

  return (
    <>
      {propertiesList.length > 0 && (
        <>
          <SliderHeader
            title={`Stay in ${strapiCity.name}`}
            totalLength={propertiesList.length}
          />
          <Slider {...props}>
            {propertiesList.map((property) => {
              return (
                <Link
                  key={property.id}
                  sx={{
                    all: "unset",
                  }}
                  to={propertyPageUrl(property)}
                >
                  <Card
                    type="white"
                    orientation="portrait"
                    size={variantSelector(["large", null, "extra_large"])}
                    image={property.image}
                    sx={{
                      height: "full",

                      cursor: "pointer",
                    }}
                  >
                    <Flex
                      sx={{ flexDirection: "column", height: "full", py: 3 }}
                    >
                      <Heading
                        as="h4"
                        variant={variantSelector([
                          "subtitle1",
                          null,
                          "heading6",
                        ])}
                        sx={{ mb: [2, null, 4] }}
                      >
                        {property.name}
                      </Heading>
                      <LimitedLines
                        variant={variantSelector(["body2", null, "body1"])}
                        lines={3}
                        sx={{
                          mb: 4,
                        }}
                      >
                        {property.description}
                      </LimitedLines>
                      {property.contactInfo?.address && (
                        <Text
                          sx={{ mt: "auto" }}
                          variant={variantSelector(["body3", null, "body2"])}
                          color="text.secondary"
                        >
                          {formatedAddress(property.contactInfo)}
                        </Text>
                      )}
                    </Flex>
                  </Card>
                </Link>
              );
            })}
          </Slider>
        </>
      )}
    </>
  );
};

export default PropertiesSlider;
