import React, { useState, useMemo } from "react";
import { Box, Heading, Paragraph, Input, Text, Flex } from "theme-ui";

import SearchIcon from "@material-ui/icons/Search";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import LocalActivityOutlinedIcon from "@material-ui/icons/LocalActivityOutlined";

import { navigate } from "gatsby";

import SearchComponent from "components/SearchComponent";
import Layout from "components/Layout";
import VendorSlider from "explore/components/VendorSlider";
import FeaturedExperiences from "explore/components/FeaturedExperiences";
import PropertiesSlider from "./components/PropertiesSlider";
import TagSlider from "vendors/components/TagSlider";
import DoubleDatePickerField from "components/Fields/DoubleDatePicker";
import FilterInput from "components/Fields/DatePicker/FilterInput";
import SliderHeader from "components/SliderHeader";
import Image from "components/Image";

import { filterVendorListByTagId } from "utils/filterVendorListByTag";
import { useVariantByBreakpoint } from "utils";
import { cityPageUrl, experiencePageUrl, vendorPageUrl } from "utils/urls";

const PAGE_NAME = "Explore";

export default function DestinationPage({
  strapiCity,
  vendorList,
  propertiesList,
  vendorTagsList,
}) {
  const [selectedSearchOption, setSelectedSearchOption] = useState(null);
  const variantSelector = useVariantByBreakpoint();

  const handleSelectedSearchOption = (event, value, reason) => {
    if (value.type === "vendor") {
      navigate(vendorPageUrl(value));
    } else {
      navigate(
        experiencePageUrl(strapiCity, {
          tagId: value.id,
        })
      );
    }
  };

  const optionsForSearch = useMemo(
    () => [vendorList, vendorTagsList].flat(),
    [vendorList, vendorTagsList]
  );

  const handleTagSelection = (tag) => {
    navigate(
      experiencePageUrl(strapiCity, {
        tagId: tag.id,
      })
    );
  };

  const handleDateSelection = (range) => {
    if (!range.start) return;

    navigate(
      experiencePageUrl(strapiCity, {
        from: range.start,
        to: range.end,
      })
    );
  };

  const topVendors = useMemo(() => {
    return vendorList.filter((vendor) => vendor.isFeatured);
  }, [vendorList]);

  const BREADCRUMB_LINKS = [
    { text: "Explore", to: "/" },
    {
      text: `${strapiCity.name}`,
      to: cityPageUrl(strapiCity),
    },
  ];

  return (
    <Layout page={PAGE_NAME} breadcrumbs={BREADCRUMB_LINKS}>
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "row-reverse"],
          justifyContent: "center",
          maxWidth: "maxContent",
          minHeight: 120,
          gap: [0, 10],
          px: [null, 10],
          mx: "auto",
          mt: [0, 10],
          mb: [10, null, 20],
        }}
        className="fullSize"
      >
        <Image
          image={strapiCity.image}
          sx={{
            zIndex: "negative",
            width: ["100%", "47%", "60%"],
          }}
          alt="City image"
        />
        <Box
          sx={{
            display: ["block", "flex"],
            flexDirection: "column",
            justifyContent: "end",
            flex: 1,
            px: [4, 0],
            mt: "-10px",
            bg: "background",
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
          }}
        >
          <Box
            sx={{
              order: "3",
              display: "block",
              width: ["100%", "140%"],
              flexDirection: ["column", "row"],
              transform: ["translateY(-50%)", null],
              mb: [0, 10],
              mt: [null, 10],
            }}
          >
            <SearchComponent
              options={optionsForSearch}
              selectedSearchOption={selectedSearchOption}
              setSelectedSearchOption={setSelectedSearchOption}
              sx={{ zIndex: "low", boxShadow: "elevation3dp" }}
              renderInput={(params) => {
                return (
                  <Box
                    ref={params.InputProps.ref}
                    sx={{
                      variant: "forms.input",
                      display: "flex",
                      alignItems: "center",
                      height: 14,
                      borderWidth: 0,
                      pl: 5,
                      py: 2,
                    }}
                  >
                    <Flex sx={{ fontSize: 6, color: "primary" }}>
                      <SearchIcon />
                    </Flex>
                    <Input
                      sx={{ border: "none", fontFamily: "input" }}
                      type="text"
                      placeholder={"What are you in the mood for?" || ""}
                      {...params.inputProps}
                    />
                  </Box>
                );
              }}
              renderOption={(option) => (
                <Box sx={{ display: "flex", flexDirection: "row " }}>
                  <Text
                    sx={{
                      display: "inline-flex",
                      fontSize: 6,
                      color: "text.disabled",
                    }}
                  >
                    {option.type === "vendor" ? (
                      <LocalActivityOutlinedIcon />
                    ) : (
                      <LabelOutlinedIcon />
                    )}
                  </Text>
                  <Paragraph variant="body1" sx={{ marginLeft: 2 }}>
                    {option.name}
                  </Paragraph>
                </Box>
              )}
              handleChange={handleSelectedSearchOption}
            />
          </Box>
          {/* <BookingDetails
            sx={{ display: ["none", "block"], order: 4, my: 0 }}
          /> */}
          <Heading
            as="h3"
            variant={variantSelector(["heading4", "heading3"])}
            sx={{ order: "1", pt: [0, 8], mb: 4 }}
          >
            {strapiCity.caption}
          </Heading>
          <Paragraph variant="body1" sx={{ mb: 10, order: "2" }}>
            {strapiCity.description}
          </Paragraph>
          {/* <BookingDetails sx={{ display: ["block", "none"] }} /> */}
        </Box>
      </Box>

      <Flex
        sx={{
          gap: 4,
          alignItems: "center",
          mb: [10, null, 20],
        }}
      >
        <DoubleDatePickerField
          sx={{
            display: ["none", null, "block"],
            width: 100,
          }}
          input={
            <FilterInput name="date-filter" onSearch={handleDateSelection} />
          }
        />
        <TagSlider
          tags={vendorTagsList}
          handleClick={handleTagSelection}
          sx={{ flex: 1 }}
        />
      </Flex>

      <FeaturedExperiences
        vendorList={topVendors}
        title={`Top experiences in ${strapiCity.name}`}
        subtitle={`Places to see, ways to wander, and signature experiences that define ${strapiCity.name}.`}
        linkText={"View all experiences"}
        link={experiencePageUrl(strapiCity)}
        sx={{ mb: 20 }}
      />

      {strapiCity.featured_vendor_tags &&
        strapiCity.featured_vendor_tags.length > 0 && (
          <>
            {strapiCity.featured_vendor_tags.map((featuredVendorTag) => {
              const filteredList = filterVendorListByTagId(
                vendorList,
                featuredVendorTag.id
              );
              if (filteredList.length > 0) {
                return (
                  <React.Fragment key={featuredVendorTag.title}>
                    <SliderHeader
                      title={featuredVendorTag.title}
                      link={experiencePageUrl(strapiCity, {
                        tagId: featuredVendorTag.id,
                      })}
                      totalLength={filteredList.length}
                    />
                    <VendorSlider
                      vendorList={filteredList}
                      sx={{
                        mb: 10,
                      }}
                      className="fullSize"
                    />
                  </React.Fragment>
                );
              }
            })}
          </>
        )}

      <PropertiesSlider
        propertiesList={propertiesList}
        strapiCity={strapiCity}
        sx={{
          mb: 10,
        }}
        className="fullSize"
      />
    </Layout>
  );
}
