import React from "react";
import { graphql } from "gatsby";

import DestinationPage from "explore/DestinationPage";
import SEO from "components/SEO";

import { filterByLimitedTime } from "utils/filterBy";
import getImage from "utils/getImage";

export default function Destination({ data, pageContext }) {
  const strapiCity = {
    ...data.strapiCity,
    image: getImage(data.strapiCity.hero_image),
  };

  const propertiesList = data.allStrapiProperty.nodes.map((property) => ({
    ...property,
    image: getImage(property.hero_image),
  }));

  const vendorList = data.allStrapiVendor.nodes
    .filter(filterByLimitedTime)
    .map((vendor) => ({
      ...vendor,
      image: getImage(
        vendor.hero_image,
        data.strapiRecommendsConfig?.defaultVendorImage
      ),
      type: "vendor",
    }));

  const sortedVendorTags = pageContext.vendorTags.sort(
    (a, b) => b.count - a.count
  );

  return (
    <DestinationPage
      propertiesList={propertiesList}
      strapiCity={strapiCity}
      vendorList={vendorList}
      vendorTagsList={sortedVendorTags}
    />
  );
}

export const Head = ({ data }) => (
  <SEO title={`Explore ${data?.strapiCity?.name || "Destination"}`} />
);

export const query = graphql`
  query DestinationQuery($slug: String, $propertyIds: [Int]) {
    strapiCity(slug: { eq: $slug }) {
      id: strapi_id
      slug
      caption
      description
      name
      hero_image {
        ...strapiMediaFragment
      }
      featured_vendor_tags {
        title
        subtitle
        id: strapi_id
      }
    }

    allStrapiVendor(filter: { property_id: { in: $propertyIds } }) {
      nodes {
        slug
        axpVendorId: axp_vendor_id
        display_name
        name
        description
        displayFrom: display_from
        expiresAt: expires_at
        isFeatured: featured_vendor
        contactInfo: contact_info {
          address {
            address_line_1
            city
            country
          }
        }
        hero_image {
          ...strapiMediaFragment
        }
        tags: vendor_tags {
          id: strapi_id
          title
        }
        propertyId: property_id
      }
      totalCount
    }

    allStrapiProperty(filter: { strapi_id: { in: $propertyIds } }) {
      nodes {
        slug
        name
        id: strapi_id
        description
        contactInfo: contact_info {
          address {
            address_line_1
            city
          }
        }
        hero_image {
          ...strapiMediaFragment
        }
      }
    }

    strapiBrandHome {
      hero_images {
        ...strapiMediaFragment
      }
    }

    strapiRecommendsConfig {
      defaultVendorImage: default_vendor_image {
        ...strapiMediaFragment
      }
    }
  }
`;
